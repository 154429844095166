import type { _BaseType } from './_base_type'
import BaseModel from './base'

export type ProductRatingType = _BaseType & {
    productId: string
    totalRate: number
    totalUser: number
    rating?: {
        userId: string
        rate: number
        remark?: string
    }[]
}
export class ProductRatingModel extends BaseModel {
    constructor() {
        super('productRatings')
    }
}
