import type { ObjectId } from 'bson'
import { dataFetchSingleWhere } from '~/realm/data/get'
import type { StateType } from '~/models/state'
import { StateModel } from '~/models/state'
import { dataFetchList } from '~/realm/data/list'
import { ProductShippingMarkupModel } from '~/models/product_shipping_markup'
import type { ProductType } from '~/models/product'
import { ProductModel } from '~/models/product'
import { ProductRatingModel } from '~/models/product_rating'
import type { CartType } from '~/models/cart'
import { useAclStore } from '~/store/acl'

const productModel = new ProductModel()
const productRatingModel = new ProductRatingModel()
const stateModel = new StateModel()

export const getCart = async (): Promise<CartType | null> => {
  const $acl = useAclStore()
  // const { cart } = await $acl.loadCart() || { cart: null }
  // console.log('getcart ok')
  return $acl.cart
}
const getProductsRatings = (productIds: ObjectId[]) => dataFetchList(productRatingModel, { filters: { productId: { $in: productIds } }, page: 1, limit: 9999, sorts: [] })
const getShippingMarkup = (zoneIds: ObjectId[], productIds: ObjectId[]) => dataFetchList<ProductType>(new ProductShippingMarkupModel(), {
  filters: {
    zoneId: { $in: zoneIds },
    productId: { $in: productIds },
  },
  page: 1,
  limit: 9999,
  sorts: [],
})

const getState = (param): Promise<StateType | null> => dataFetchSingleWhere<StateType>(stateModel, { code: param })

export async function getProducts(
  param = { filters: {}, page: 1, limit: 9999, sorts: [{ field: '_id', dir: 'desc' }] }
) {
  const cart = await getCart();

  const productRes = await (async() => {
    if (typeof window === 'undefined') {
      const { cachableDataFetchList } = await import('~/realm/data/list')
      return await (await cachableDataFetchList())(productModel, param)
    }
    return dataFetchList<ProductType>(productModel, param);
  })()
  console.log('getProducts', Object.keys(productRes))
  const products = productRes.list;
  const productIds = products.map((p) => p._id);

  let zoneIds = [] as ObjectId[];
  const state = cart?.shippingAddress?.state ? await getState(cart?.shippingAddress?.state) : null;
  console.log('state?', cart?.shippingAddress?.state, state);
  zoneIds = state?.zones || [];
  const [markUps, ratings] = await Promise.all([
    getShippingMarkup(zoneIds, productIds),
    getProductsRatings(productIds),
  ]);
  // console.log(
  //   'markups',
  //   zoneIds.map((id) => id.toString()),
  //   productIds.map((id) => id.toString()),
  //   markUps
  // );
  if (markUps.list.length > 0 || ratings.list.length > 0) {
    for (const p of products) {
      const markup = markUps.list.find((m) => m.productId.toString() === p._id.toString());
      if (markup) {
        p.unitPrice = Number.parseFloat(p.unitPrice) + Number.parseFloat(markup.amount);
        p.normalUnitPrice = Number.parseFloat(p.normalUnitPrice) + Number.parseFloat(markup.amount);
      }

      const rating = ratings.list.find((r) => r.productId.toString() === p._id.toString());
      if (rating) {
        p.rating = rating.rating || [];
        p.totalRate = rating.totalRate ?? 0;
        p.totalUser = rating.totalUser ?? 0;
      }
    }
  }

  return { ...productRes, list: products };
}
